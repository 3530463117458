<template>
    <v-container class="d-flex justify-center">
        <StatsList role="leader" />
    </v-container>
</template>

<script>
export default {
    components: {
        StatsList: () => import('@/components/StatsList'),
    },
}
</script>

<style scoped></style>
