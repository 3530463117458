import { render, staticRenderFns } from "./LeadersDashboard.vue?vue&type=template&id=28117b34&scoped=true&"
import script from "./LeadersDashboard.vue?vue&type=script&lang=js&"
export * from "./LeadersDashboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28117b34",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})
